

export const ICON_TYPE = {
    ICON: 1,
    IMAGE: 2
}
export const APLHABET_CONFIGURATION = {
    CONF_NONE: { value: '0', label: 'None' },
    CONF_ONLY_UPPER_CASE: { value: '1', label: 'Only Upper Case' },
    CONF_ONLY_LOWER_CASE: { value: '2', label: 'Only Lower Case' },
    CONF_UPPER_LOWER: { value: '3', label: 'Upper Lower' },
    CONF_LOWER_UPPER: { value: '4', label: 'Lower Upper' },
}
export const CONFIGURATION_TYPES = {
    'OtaVea': { value: 'vega', label: 'Ota Vega' },
    'OtaGlogi': { value: 'glogi', label: 'Ota Glogi' }
}
export const COUNTRY_CODES = {
    EU: { value: '0', label: 'EU' },
    AS: { value: '1', label: 'US' },
    JP: { value: '2', label: 'JP' },
}

export const ENTERPRISE_CONNECTION = {
    'TLS': { value: '1', label: 'TLS' },
    'TTLS_MSCHAPv2': { value: '3', label: 'TTLS_MSCHAPv2' },
    'PEAP0_MSCHAPv2': { value: '6', label: 'PEAP0_MSCHAPv2' },
    'PEAP0_PSK': { value: '7', label: 'PEAP0_PSK' },
}

export const FREQUENCY_TYPES = {
    DUAL_MODE: { value: '0', label: 'Dual Mode' },
    GZH_24: { value: '1', label: '2.4 GHz Only' },
    GZH_5: { value: '2', label: '5 GHz Only' },
}

export const LOCAL_STORAGE_KEYS = {
    IS_USER_AUTH: "IS_USER_AUTH_VISIONTOOL",
    USER_INFO: "USER_INFO_VISIONTOOL",
    SELECTED_PROJECT: "SELECTED_PROJECT_VISIONTOOL",
    SELECTED_PROJECT_IMAGES: "SELECTED_PROJECT_IMAGES_VISIONTOOL",
    SELECTED_PROJECT_ID: "SELECTED_PROJECT_ID_VISIONTOOL",
    PROJECT_DATE_RANGE: "PROJECT_DATE_RANGE_VISIONTOOL",
    SELECTED_PROJECT_STATUS: "SELECTED_PROJECT_STATUS_VISIONTOOL",
}
export const BARCODE_KEYWORDS = {
    SECURITY_TYPE: "!*WFST+!",
    SSID: "!*SSID+!",
    PASS: "!*PASS+!",
    ENTERPRISE_CONNECTION: "!*WEAP+!",
    CONFIGURATION_ADDRESS: "!*WNCA+!",
    CONFIGURATION_ADDRESS_STATIC: "!*WSIP+!",
    SERVER_URL: "!*WURL+!",
    LOG_URL: "!*WLUA+!",
    SOUND_URL: "!*BEKLENIYOR+!",
    NONE: "!*WASI+!",
    COUNTRY_CODE: "!*WFRC+!",
    FREQUENCY_TYPE: "!*WFFB+!",
    TIMEOUT: "!*WTIM+!",
    LANGUAGE: "!*WFDL+!",
    THRESHOLD: "!*WRRS+!",
    KEYPAD: "!*KCON+!",
    SCREEN_BACKLIGHT: "!*SPWM+!",
    SHORTCUT: "!*KDAT+!",
    LOG_PERIOD: "!*WLDI+!",
    PROJECT_ID: "!*PRJCT+!",

}

export const NEW_LINE = "\n"
export const SECURITY_TYPES = {
    'WPA_WPA2': { value: '0', label: 'WPA/WPA2 Personal' },
    'WEP': { value: '1', label: 'WEP' },
    'WPAEnterprise': { value: '2', label: 'WPA/WPA2 Enterprise' },
    'NoPassword': { value: '3', label: 'WPA/WPA2 No Password' },
    'WEPShared': { value: '5', label: 'WEP Shared' }
}
export const URL_TYPES = {
    HTTP: { value: 'http', label: 'http' },
    HTTPS: { value: 'https', label: 'https' },
   TELNET: { value: 'telnet', label: 'telnet' },
}
export const LANGUAGES = {
    'English': { value: '0', label: 'English' },
    'Turkish': { value: '1', label: 'Turkish' },
    'German': { value: '2', label: 'German' },
    'Spanish': { value: '3', label: 'Spanish' },
    //'Chinese' : { value: '5', label: 'Chinese' },
    'Portuguese': { value: '5', label: 'Portuguese' }
}
export const CONFIGURATION_ADDRESS = {
    DHCP: { value: '1', label: 'DHCP' },
    Static: { value: '4', label: 'Static' },
}
export const KEYPAD_DEFAULT_VALUES = {
    fn0: "\\0",
    fn1: "\\0",
    fn2: "\\0",
    fn3: "\\0",
    fn4: "\\0",
    fn5: "\\0",
    fn6: "\\0",
    fn7: "\\0",
    fn8: "\\0",
    fn9: "\\0",
    fnBSP: "\\0",
    fnUP: "\\0",
    fnOK: "\\0",
    fnDown: "\\0",
    down: "\\0",
    ok: "\\0",
    up: "\\0",
    bsp: "\\0",
    shortClick: "\\0",
    doubleClick: "\\0",
    scanEndOf: "\\0",
}
export const KEYPAD_XTERM_VALUES = {
    fn0: "\\0",
    fn1: "\\eOP",
    fn2: "\\eOQ",
    fn3: "\\eOR",
    fn4: "\\eOS",
    fn5: "\\e[15~",
    fn6: "\\e[17~",
    fn7: "\\e[18~",
    fn8: "\\e[19~",
    fn9: "\\e[20~",
    fnBSP: "\\e",
    fnUP: "\\eOC", // Corrected based on the image
    fnOK: "\\t",
    fnDown: "\\eOD",
    down: "\\eOB",
    ok: "\\r\\n",
    up: "\\eOA",
    bsp: "\\b",
    shortClick: "\\0",
    doubleClick: "\\0",
    scanEndOf: "\\r\\n",
};

export const KEYPAD_VT_VALUES = {
    fn0: "\\0",
    fn1: "\\e[11~",
    fn2: "\\e[12~",
    fn3: "\\e[13~",
    fn4: "\\e[14~",
    fn5: "\\e[15~",
    fn6: "\\e[17~",
    fn7: "\\e[18~",
    fn8: "\\e[19~",
    fn9: "\\e[20~",
    fnBSP: "\\e",
    fnUP: "\\e[C",
    fnOK: "\\t",
    fnDown: "\\e[D",
    down: "\\e[B",
    ok: "\\r\\n",
    up: "\\e[A",
    bsp: "\\b",
    shortClick: "\\0",
    doubleClick: "\\0",
    scanEndOf: "\\r\\n",
};
export const pullSettingsFromLocalStorage = function pullSettingsFromLocalStorage() {
    if (!window || !window.localStorage) return {};
    var settings = {};

    for (var i = 0; i < window.localStorage.length; i++) {
        var key = window.localStorage.key(i);

        if (key.startsWith("settings_")) {
            try {
                settings[key.replace("settings_", "")] = JSON.parse(window.localStorage.getItem(key));
            } catch (e) { }
        }
    }

    return settings;
};
