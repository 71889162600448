import React from 'react';
import { AppContextProvider } from './contextapi/AppProvider';

import './scss/style.scss';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'flag-icon-css/css/flag-icons.min.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import '@progress/kendo-theme-default/dist/all.css';
import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { LOCAL_STORAGE_KEYS } from './helpers/constants';
import 'react-toastify/dist/ReactToastify.css';
import "react-block-ui/style.css";

i18n.use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "tr"],
    backend: {
      loadPath: '../assets/locales/{{lng}}/translation.json',
    },
    fallbackLng: "en",
    detection: {
      order: ['cookie', 'htmlTag', 'querystring', 'navigator', 'path', 'subdomain'],
      caches: ['cookie']
    },
  })

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const Home = React.lazy(() => import('./containers/pages/Home'));
const Login = React.lazy(() => import('./containers/pages/Login'));
const CreateProject = React.lazy(() => import('./containers/pages/CreateProject'));
const RecordVideo = React.lazy(() => import('./containers/pages/RecordVideo'));
const PreModelReLabel = React.lazy(() => import('./containers/pages/PreModelReLabel'));
const PreModelResult = React.lazy(() => import('./containers/pages/PreModelResult'));
const UserLabel = React.lazy(() => import('./containers/pages/UserLabel'));
const ModelTraining = React.lazy(() => import('./containers/pages/ModelTraining'));
const Projects = React.lazy(() => import('./containers/pages/Projects'));
const ProjectDetail = React.lazy(() => import('./containers/pages/ProjectDetail'));
const Deploy = React.lazy(() => import('./containers/pages/Deploy'));
const Images = React.lazy(() => import('./containers/pages/Images'));
const LiveTracking = React.lazy(() => import('./containers/pages/LiveTracking'));

const PrivateRoute = ({ children }) => {
  const isUserAuth = localStorage.getItem(LOCAL_STORAGE_KEYS.IS_USER_AUTH);

  return isUserAuth && isUserAuth === "true" ? children : <Navigate to="/login" replace />;
}

const App = () => {
  return (
    <React.Suspense fallback={loading}>
      <AppContextProvider>
        <BrowserRouter>
          <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route
              path="/"
              element={<Navigate to="/Projects" replace />}
            />
            {/* <Route path="Home" element={<PrivateRoute><Home /></PrivateRoute>} /> */}



            <Route path="Projects" >
              <Route index element={<PrivateRoute><Projects/></PrivateRoute>} />
              <Route path="CreateProject" element={<PrivateRoute><CreateProject /></PrivateRoute>} />
              <Route path="ProjectDetail" element={<PrivateRoute><ProjectDetail /></PrivateRoute>} />
              <Route path="LiveTracking/:projectName/:selectedProjectId" element={<PrivateRoute><LiveTracking /></PrivateRoute>} />
              <Route path="RecordVideo/:projectName/:selectedProjectId" element={<PrivateRoute><RecordVideo /></PrivateRoute>} />
              <Route path="UserLabel/:projectName/:selectedProjectId" element={<PrivateRoute><UserLabel /></PrivateRoute>} />
              <Route path="PreModelResult/:projectName/:selectedProjectId" element={<PrivateRoute><PreModelResult /></PrivateRoute>} />
              <Route path="PreModelReLabel/:projectName/:selectedProjectId" element={<PrivateRoute><PreModelReLabel /></PrivateRoute>} />
              <Route path="ModelTraining/:projectName/:selectedProjectId" element={<PrivateRoute><ModelTraining /></PrivateRoute>} />
              <Route path="ProjectDetail/:projectName/:selectedProjectId" element={<PrivateRoute><ProjectDetail /></PrivateRoute>}  >
                <Route path="Images" element={<Images />} />
              </Route>
              <Route path="Deploy/:projectName/:selectedProjectId" element={<Deploy />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </AppContextProvider>
    </React.Suspense>
  );
}

export default App;
